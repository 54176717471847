.App-CommandLine {
  padding-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
}

#history {
  color: white;
  /*background-color: #333333;*/
}

#inputchatmessage {
  background-color: white;
  border: 1px solid #eeeeee;
  border-color: gray;
  color: black;
}
